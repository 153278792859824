import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'

import { SERVER_ERROR, IDE_CONST, ROBOT_CONST } from './constants'
import { ACE_UTIL } from './ace-util'
import Split from 'split.js'
import loadScriptInBody from '@/assets/javascript/loadScriptInBody'

export default {
  data: function () {
    return {
      isEmbed: false,
      codeEditor: null,
      fileEditor: null,
      outputEditor: null,
      editors: [],
      versionIndex: 0,
      args: null,
      stdin: null,
      outputFiles: [],
      isCodeExecuting: false,
      javaLibraries: [],
      executionTime: null,
      memory: 0,
      cpuTime: 0,
      wsNextId: 0,
      isLocalStoreEnabled: false,
      storage: null,
      maxFileSize: 5000000,
      inputFiles: [],
      maxUploads: 5,
      uploadInProgress: false,
      uploadMessage: null,
      currentUpload: null,
      fontSize: localStorage.getItem('JDoodle-Font-Size') ? localStorage.getItem('JDoodle-Font-Size') : 12,
      blocklyWorkspace: {},
      blocklyLanguages: {},
      blockList: {
        categories: ['Logic', 'Controls', 'Lists', 'Loops', 'Color', 'Math', 'Texts', 'Variables', 'Variables Dynamic', 'Procedures'],
        blockTypes: [['logic_boolean', 'logic_compare', 'logic_operation', 'logic_negate', 'logic_null', 'logic_ternary'],
          ['controls_if', 'controls_ifelse', 'controls_if_if', 'controls_if_elseif', 'controls_if_else', 'logic_ternary'],
          ['lists_repeat', 'lists_reverse', 'lists_isEmpty', 'lists_length', 'lists_create_with', 'lists_create_with_container', 'lists_create_with_item', 'lists_indexOf', 'lists_getIndex', 'lists_setIndex', 'lists_getSublist', 'lists_sort', 'lists_split' ],
          ['controls_repeat_ext', 'controls_repeat', 'controls_whileUntil', 'controls_for', 'controls_forEach', 'controls_flow_statements'],
          ['colour_picker', 'colour_random', 'colour_rgb', 'colour_blend'],
          ['math_number', 'math_arithmetic', 'math_single', 'math_trig', 'math_constant', 'math_number_property', 'math_change', 'math_round', 'math_on_list', 'math_modulo', 'math_constrain', 'math_random_int', 'math_random_float', 'math_atan2'],
          ['text_print', 'text', 'text_multiline', 'text_join', 'text_create_join_container', 'text_create_join_item', 'text_append', 'text_length', 'text_isEmpty', 'text_indexOf', 'text_charAt',
            'text_getSubstring', 'text_changeCase', 'text_trim', 'text_prompt_ext', 'text_prompt', 'text_count', 'text_replace', 'text_reverse' ],
          ['variables_get', 'variables_set'],
          ['variables_get_dynamic', 'variables_set_dynamic'],
          ['procedures_defnoreturn', 'procedures_defreturn', 'procedures_mutatorcontainer', 'procedures_mutatorarg', 'procedures_callnoreturn', 'procedures_callreturn', 'procedures_ifreturn' ]
        ]
      }
    }
  },
  methods: {
    initEditors (count) {
      if (count > 10) {
        return
      }

      if (!window.ace) {
        this.$_.delay(this.initEditors, 500, count + 1)
        return
      }

      if (this.ideSplit) {
        this.ideSplit.destroy()
        this.ideSplit = null
      }

      if (this.isMultiFileLang) {
        this.ideSplit = Split(['#ide-tree', '#ide-code'], {
          sizes: [25, 75],
          minSize: [25, 500]
        })
      }

      if (this.codeEditor) {
        if (this.$route.meta.sampleScript && !this.isBlockly) {
          this.codeEditor.getSession().setValue(this.$route.meta.sampleScript)
        }
        ACE_UTIL.changeLanguage(this.codeEditor, this.aceLanguageCode)
      } else {
        this.initCodeEditor()
      }

      if (this.outputEditor) {
        this.outputEditor.getSession().setValue('')
        this.executionTime = null
      } else {
        this.initOutputEditor()
      }
      if (this.versions) {
        this.versionIndex = (this.defaultVersion) ? this.defaultVersion : this.versions.length - 1
      }
    },
    initBlockly () {
      let toolbox = {
        kind: 'categoryToolbox',
        contents: [
        ]
      }

      for (let category in this.blockList.categories) {
        let newCategory = {
          kind: 'category',
          name: this.blockList.categories[category],
          contents: []
        }

        for (let blk in this.blockList.blockTypes[category]) {
          newCategory.contents.push({
            kind: 'block',
            type: this.blockList.blockTypes[category][blk]
          })
        }

        toolbox.contents.push(newCategory)
      }

      let postAction = (count) => {
        if (window.Blockly) {
          this.blocklyWorkspace = window.Blockly.inject('blocklyEditor', { toolbox: toolbox })
          this.blocklyWorkspace.addChangeListener(this.blocklyUpdate)
          this.blocklyLanguages = [window.Blockly.Dart, window.Blockly.Lua, window.Blockly.PHP, window.Blockly.Python]
          if (this.isEmbed || this.$route.meta.isPlugin) {
            window.Blockly.serialization.workspaces.load(JSON.parse(this.project.script), this.blocklyWorkspace, false)
            this.pymChild.sendHeight()
            this.onLanguageChange()
          } else if (this.isShared) {
            window.Blockly.serialization.workspaces.load(JSON.parse(this.project.script), this.blocklyWorkspace, false)
            this.versionChanged()
          } else if (this.$route.meta.sampleScript) {
            window.Blockly.serialization.workspaces.load(JSON.parse(this.$route.meta.sampleScript), this.blocklyWorkspace, false)
          }
        } else if (count < 10) {
          window._.delay(postAction, 600, count + 1)
        }
      }

      postAction(0)
    },
    loadBlockly (lang, vm) {
      if (lang === 'blockly') {
        loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/blockly_compressed.js').then(() => {
          loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/msg/js/en.js').then(() => {
            loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/blocks_compressed.js').then(() => {
              loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/javascript_compressed.js')
              loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/lua_compressed.js')
              loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/dart_compressed.js')
              loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/php_compressed.js')
              loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/python_compressed.js')
              vm.$_.delay(vm.initBlockly, 500, 1)
            })
          })
        })
      }
    },
    blocklyUpdate () {
      this.codeEditor.getSession().setValue(this.blocklyLanguages[this.versionIndex].workspaceToCode(this.blocklyWorkspace))
    },
    codeEditorHeightChangeFunction () {
      ACE_UTIL.heightChangeFunction(IDE_CONST.CODE_EDITOR)
    },
    outputEditorHeightChangeFunction () {
      ACE_UTIL.heightChangeFunction(IDE_CONST.OUTPUT_EDITOR)
    },
    initCodeEditor () {
      let aceLangCode = this.aceLanguageCode

      if (this.language === 'blockly') {
        aceLangCode = 'python'
      }

      this.codeEditor = ACE_UTIL.initEditor(IDE_CONST.CODE_EDITOR, aceLangCode)
      this.codeEditor.setFontSize(this.fontSize + 'px')
      this.codeEditor.renderer.setShowGutter(true)
      if (this.language !== 'blockly') {
        this.codeEditor.getSession().on('change', this.codeEditorHeightChangeFunction)
      }

      if (this.isEmbed && !this.$route.meta.isPlugin && this.$route.query.rw !== '1') {
        this.codeEditor.setReadOnly(true)
      }

      if (this.language === 'whitespace') {
        this.codeEditor.setShowInvisibles(true)
      }

      if (this.$route.meta.sampleScript && !this.isBlockly) {
        this.codeEditor.getSession().setValue(this.$route.meta.sampleScript)
      }

      ACE_UTIL.heightChangeFunction(IDE_CONST.CODE_EDITOR)

      document.addEventListener('keyup', (event) => {
        if (event.ctrlKey && event.key === 'Enter') {
          this.tryExecute()
        }
      }, false)
    },
    initOutputEditor () {
      this.outputEditor = ACE_UTIL.initEditor(IDE_CONST.OUTPUT_EDITOR)
      this.outputEditor.getSession().on('change', this.outputEditorHeightChangeFunction)
      this.outputEditor.renderer.setPadding(20)
      this.outputEditor.renderer.setScrollMargin(20, 20)
      this.outputEditor.setDisplayIndentGuides(false)

      ACE_UTIL.heightChangeFunction(IDE_CONST.OUTPUT_EDITOR)

      $(window.ace.edit(IDE_CONST.OUTPUT_EDITOR).textInput.getElement()).keypress((event) => {
        if (this.interactiveMode && this.isCodeExecuting) {
          let key = event.key
          if (event.key === 'Enter') {
            key = '\n'
          }
          this.socketClient.send('/app/execute-i', key, { message_type: 'input' })
        }
      })
    },
    stopExecution () {
      if (!this.isCodeExecuting) {
        return
      }

      if (this.interactiveMode) {
        this.socketClient.disconnect(() => {
          this.isCodeExecuting = false
        })
      } else {
        this.executeXHR.abort()
        this.isCodeExecuting = false
      }
    },
    tryExecute () {
      if (this.isCodeExecuting) {
        return
      }

      this.isCodeExecuting = true
      this.executionTime = null
      this.outputFiles = []
      window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue('JDoodle in Action.... Running the program...')

      this.callViaCaptcha(this.ideExecute, this.captchaFailCallback)
    },
    captchaFailCallback () {
      window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue(ROBOT_CONST.ROBOT_IDE_ERROR)
      this.isCodeExecuting = false
    },
    execute (isMultiFile) {
      if (this.interactiveMode) {
        this.stdin = null
      }

      let executeLogic = () => {
        let requestData

        let language = (this.isBlockly) ? this.ideMeta.blocklyLanguages[this.versionIndex] : this.language
        let versionIndex = (this.isBlockly) ? this.ideMeta.blocklyLanguageVersions[this.versionIndex] : this.versionIndex
        let script = window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue()

        if (this.isBlockly && this.laguage === 'php') {
          script = '\n' + script + '\n'
        }

        if (isMultiFile) {
          requestData = JSON.stringify({
            language: language,
            versionIndex: versionIndex,
            projectKey: this.projectKey,
            multiFile: true,
            libs: this.javaLibraries,
            mainFile: this.project.home.substring(1),
            args: this.args,
            stdin: this.stdin,
            hasInputFiles: (this.inputFiles.length > 0)
          })
        } else {
          requestData = JSON.stringify({
            script: script,
            args: this.args,
            stdin: this.stdin,
            language: language,
            versionIndex: versionIndex,
            libs: this.javaLibraries,
            projectKey: 1001,
            hasInputFiles: (this.inputFiles.length > 0)
          })
        }

        if (this.interactiveMode) {
          this.executeInteractive(requestData)
          return
        }

        let startTime = this.getCurrentTime()

        let url = '/engine/execute'
        this.executeXHR = $.ajax({
          url: url,
          method: 'POST',
          contentType: 'application/json',
          data: requestData
        }).done((data, textStatus, jqXHR) => {
          if (data.output) {
            window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue(data.output)
          } else {
            window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue('')
          }
          if (data.statusCode === 403) {
            this.clearRobotCheckStatus()
          }
          this.executionTime = data.executeTime
          this.cpuTime = data.cpuTime
          this.memory = data.memory
          this.outputFiles = data.outputFiles
          if (!this.isMultiFile) {
            this.postExecuteRecentHandling()
          }
          this.postExecuteSuccessHandling()
        }).fail((jqXHR, textStatus, errorThrown) => {
          window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue(SERVER_ERROR)
          window.jda.exception(url + ': ' + errorThrown)
        }).always(() => {
          this.isCodeExecuting = false
          this.calculateAndSendExecuteEndTime(startTime, 'execute')
        })

        window.jda.pageEvent(window.jda.CATEGORY.IDE, 'execute', this.language)

        if (this.autoSaveOn && this.project && this.project.id) {
          let data

          if (this.isMultiFileLang) {
            let root = this.$_.cloneDeep(this.project)
            this.getSanitizedScriptIt(root.treeData)
            let script = JSON.stringify({ home: root.home, treeData: root.treeData })
            data = {
              script: script,
              libs: (this.javaLibraries) ? this.javaLibraries.join(' ') : '',
              versionIndex: this.versionIndex,
              projectKey: this.projectKey,
              isMultiFile: true
            }
          } else {
            data = {
              script: window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue(),
              libs: (this.javaLibraries) ? this.javaLibraries.join(' ') : '',
              versionIndex: this.versionIndex,
              isMultiFileLang: false
            }
          }

          data.filename = this.project.name
          data.id = this.project.id
          data.lang = this.language

          this.executeAPIWitoutValiation({
            url: '/api/doodle/save',
            data: data,
            method: 'post',
            markCompleted: true,
            form: this.doodleForm,
            jdaCategory: window.jda.CATEGORY.IDE,
            jdaEvent: 'save-auto',
            jdaLabel: this.language
          })
        }
      }

      let multifileWaitForSync = (count) => {
        if (this.$refs.projectTree.isSyncSuccess()) {
          executeLogic()
        } else if (count > 7) {
          window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue('Unable to Sync files with Server. Please try again or contact JDoodle Support')
          this.isCodeExecuting = false
        } else {
          this.$_.delay(multifileWaitForSync, 1000, count + 1)
        }
      }

      if (isMultiFile) {
        this.$refs.projectTree.syncBeforeExecute()
        this.$_.delay(multifileWaitForSync, 1000, 1)
      } else {
        executeLogic()
      }
    },
    getCurrentTime () {
      try {
        if (window.performance) {
          return performance.now()
        }
      } catch (e) {
        return $.now()
      }

      return 0
    },
    calculateAndSendExecuteEndTime (startTime, category) {
      // eslint-disable-next-line no-unused-vars
      let execTime = this.getCurrentTime()

      window.ga('send', {
        hitType: 'timing',
        timingCategory: category,
        timingVar: this.language,
        timingValue: execTime - startTime
      })
    },
    getSanitizedScriptIt (root) {
      if (root.content) {
        root.content = null
      }

      if (root.codeChanged) {
        root.codeChanged = false
      }

      if (root.children) {
        for (let child of root.children) {
          this.getSanitizedScriptIt(child)
        }
      }
    },
    postExecuteRecentHandling () {
      var lastExecution = {
        script: window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue(),
        args: this.args,
        stdin: this.stdin,
        libs: this.javaLibraries,
        output: window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().getValue(),
        executedAt: $.now(),
        versionIndex: this.version
      }
      this.addToRecent(lastExecution)
    },
    postExecuteSuccessHandling () {

    },
    executeInteractive (data) {
      this.socketClient = Stomp.over(new SockJS('/engine/stomp'), { heartbeat: false, debug: false })
      window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue('')

      this.wsNextId = 0

      let startTime = this.getCurrentTime()

      this.socketClient.connect({}, () => {
        this.onWsConnection(startTime, data)
      }, this.onWsConnectionFailed)
      window.jda.pageEvent(window.jda.CATEGORY.IDE, 'execute-i-start', this.language)
    },
    onWsConnectionFailed (e) {
      this.isCodeExecuting = false
      window.ace.edit('output').insert('Connection to server lost.\n It is possible your browser or internet connection may not support the ' +
        'Interactive mode.\n Please try again, or try Non-Interactive mode. Alternatively contact JDoodle ' +
        'support at hello@jdoodle.com.')
      window.jda.exception(e)
    },
    postInteractiveExecute (startTime) {
      this.socketClient.disconnect()
      this.isCodeExecuting = false

      window.jda.pageEvent(window.jda.CATEGORY.IDE, 'execute-i-end', this.language)
      this.calculateAndSendExecuteEndTime(startTime, 'execute-i')
      this.postExecuteSuccessHandling()
    },
    onWsConnection (startTime, data) {
      this.socketClient.subscribe('/user/queue/execute-i', (message) => {
        let msgId = message.headers['message-id']
        let msgSeq = parseInt(msgId.substring(msgId.lastIndexOf('-') + 1))

        let statusCode = parseInt(message.headers.statusCode)
        if (statusCode !== 200 && statusCode !== 201 && statusCode !== 410 && statusCode !== 204) {
          window.jda.pageEvent(window.jda.CATEGORY.IDE, 'execute-i-status', this.language, statusCode)
        }

        if (statusCode === 201) {
          this.wsNextId = msgSeq + 1
          return
        }

        let t0
        try {
          t0 = performance.now()
          while ((performance.now() - t0) < 2500 && this.wsNextId !== msgSeq) {

          }
        } catch (e) {

        }

        if (statusCode === 204) {
          this.executionTime = message.body
          this.postInteractiveExecute(startTime)
        } else if (statusCode === 500) {
          window.ace.edit('output').getSession().setValue(SERVER_ERROR)
          this.isCodeExecuting = false
        } else if (statusCode === 206) {
          this.outputFiles = JSON.parse(message.body)
        } else if (statusCode === 403) {
          this.clearRobotCheckStatus()
          window.ace.edit('output').insert('' + message.body)
        } else if (statusCode !== 410) {
          window.ace.edit('output').insert('' + message.body)
        }

        this.wsNextId = msgSeq + 1
      })

      this.socketClient.send('/app/execute-i', data, { message_type: 'execute' })
      window.ace.edit('output').focus()
    },
    reset () {
      if (window.ace && $('div#code').length > 0) {
        window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue('')
        window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue('')
      }

      this.args = null
      this.stdin = null
      this.javaLibraries = []
      this.executionTime = null
      this.memory = 0
      this.cpuTime = 0
    },
    addToRecent: function (lastExecution) {
      if (this.isEmbed || this.isMultiFileLang) {
        return
      }
      if (!this.storage) {
        return
      }
      var recent = JSON.parse(this.storage.getItem('JDoodleRecent'))
      if (!recent) {
        recent = {}
      }

      if (!recent[this.language]) {
        recent[this.language] = []
      }

      recent[this.language].unshift(lastExecution)
      if (recent[this.language].length > 50) {
        recent[this.language] = this.$_.slice(recent[this.language], 0, 50)
      }

      this.storage.setItem('JDoodleRecent', JSON.stringify(recent))
    },
    versionChanged () {
      if (this.isBlockly) {
        ACE_UTIL.changeLanguage(this.codeEditor, this.ideMeta.blocklyAceCodes[this.versionIndex], this.theme)
        this.blocklyUpdate()
      }
    }
  }
}
